import React from 'react';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/layout';
import SEO from '../components/seo';

const markdown = `
# Conditions générales d’utilisation

## 1. Objet

Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur le site https://ethibox.fr ainsi que de définir les droits et obligations des parties dans ce cadre.

Elles sont notamment accessibles et imprimables à tout moment par un lien direct en bas de la page d’accueil du site.

Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation particulières à certains services. En cas de contradiction, les conditions particulières prévalent sur ces conditions générales.

## 2. Présentation du site

Ethibox est un hébergeur d'applications web (Blog, Espace de stockage, Editeur de document, Gestion de projet, Système analytics, Forum, E-commerce, ERP, Tchat, etc...)

Avec Ethibox, toutes vos applications sont entièrement infogérées.

Nous gérons et automatisons pour vous toutes les tâches complexes telles que l'installation, la configuration, la sauvegarde, la sécurisation, la surveillance, les mises à jour et l'optimisation des performances de vos applications.

## 3. Données personnelles et respect de la vie privée

Conformément à l’article 34 de la loi « Informatique et Libertés », Ethibox garantit à l’utilisateur un droit d’opposition, d’accès et de rectification sur les données nominatives le concernant. L’utilisateur a la possibilité d’exercer ce droit en [nous contactant](/support).

- Ethibox demande certaines informations personnelles : une adresse e-mail valide, votre nom et prénom
- Tout comme d’autres services en ligne, Ethibox enregistre automatiquement certaines informations concernant votre utilisation du service (exemple : type de navigateur, adresse IP, date et heure de l’accès, URL de référence).
- Nous utilisons ces informations en interne pour améliorer l’interface utilisateur d'Ethibox et maintenir une expérience utilisateur cohérente et fiable.
- Ces données ne sont ni vendues ni transmises à des tiers.

## 4. Services payants

### 4.1 Prix

Les prix des services sont indiqués sur le site.

Sauf mention contraire, ils sont exprimés en euros et toutes taxes françaises comprises.

Ethibox se réserve le droit, à sa libre discrétion et selon des modalités dont elle sera seule juge, de proposer des offres promotionnelles ou réductions de prix.

### 4.2 Révision des prix

Le prix de services peut faire l’objet d’une révision par Ethibox à tout moment, à sa libre discrétion.

L’Utilisateur sera informé de ces modifications par Ethibox par email sous un préavis de 1 mois au moins avant l’entrée en vigueur des nouveaux tarifs.

L’Utilisateur qui n’accepte pas les nouveaux prix doit mettre fin à son utilisation des services. A défaut, il sera réputé avoir accepté les nouveaux tarifs.

### 4.3 Facturation

Les services font l’objet de factures qui sont communiquées à l’Utilisateur par tout moyen utile.

### 4.4 Modalités de paiement

Les modalités de paiement du prix des services sont décrites sur le site.

Le paiement s’effectue par prélèvement automatique à partir du numéro de carte bancaire de l’Utilisateur.

Le prélèvement est mis en œuvre par le prestataire de paiement désigné sur le site, qui seul conserve les coordonnées bancaires de l’Utilisateur à cette fin. Ethibox ne conserve aucune coordonnée bancaire.

L’Utilisateur garantit à Ethibox qu'il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi. Il s’engage à prendre les mesures nécessaires afin que le prélèvement automatique du prix des services puisse être effectué.

### 4.5 Retards et incidents de paiement

L’Utilisateur est informé et accepte expressément que tout retard de paiement de tout ou partie d’une somme due à son échéance entraînera automatiquement, sans mise en demeure préalable :

- la déchéance du terme de l’ensemble des sommes dues par l’Utilisateur et leur exigibilité immédiate ;
- la suspension immédiate des services en cours jusqu’au complet paiement de l’intégralité des sommes dues par l’Utilisateur ;
- la facturation au profit d'Ethibox d’un intérêt de retard au taux de trois fois le taux de l’intérêt légal, assis sur le montant de l’intégralité des sommes dues par l’Utilisateur.

## 5. Résiliation

Ethibox, à sa seule discrétion, a le droit de suspendre ou de résilier votre compte et de refuser toute utilisation actuelle ou future du service. Cette résiliation du service entraînera la désactivation de l’accès à votre compte, et la restitution de tout le contenu.

## 6. Désinscription

Les services sont souscrits pour une durée indéterminée.

L’Utilisateur peut se désinscrire des services à tout moment sur [son espace personnel](https://secure.ethibox.fr/updateinfo/)

La désinscription est effective immédiatement. Elle entraîne la suppression de l'ensemble des données de l'utilisateur après un délai de 30 jours.

## 7. Entrée en vigueur

Les présentes conditions générales sont entrées en vigueur le 08 Juin 2019.
`;

const Cgu = () => {
    return (
        <Layout>
            <SEO title="CGU" />
            <section className="pt-28 max-w-7xl mx-auto px-4 lg:px-8">
                <Markdown
                    options={{
                        overrides: {
                            h1: { props: { className: 'font-bold text-3xl' } },
                            h2: { props: { className: 'font-bold text-2xl mt-6' } },
                            h3: { props: { className: 'font-bold text-xl mt-6' } },
                            p: { props: { className: 'my-2 text-base leading-6 text-gray-500' } },
                            ul: { props: { className: 'list-inside list-disc ml-8' } },
                            li: { props: { className: 'my-2 text-base leading-6 text-gray-500' } },
                        },
                    }}
                >
                    {markdown}
                </Markdown>
            </section>
        </Layout>
    );
};

export default Cgu;
